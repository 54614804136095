<template>
	<div class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0">
		<div class="md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-4 sm:p-12 md:p-16 sm:rounded-lg md:rounded-none sm:shadow-md md:shadow-none sm:bg-card">
			<div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
				<div class="w-12">
					<img src="@/assets/logo/logo.svg">
				</div>
				<div class="mt-8 text-4xl text-white font-extrabold tracking-tight leading-tight">Sign in</div>
				<div class="flex items-baseline mt-0.5 font-medium">
					<div class="text-white">Don't have an account?</div>
					<a class="ml-1 text-primary hover:underline" href="#">Sign up </a>
				</div>
				<!---->
				<form class="mt-8" @submit.prevent="handleLogin">
					<p v-if="message" id="error" class="text-red-500 mb-3">{{ message }}</p>

					<label class="text-white font-medium text-md">Email address</label>
					<TextInput class="text-white" type="email" placeholder="Email address" :required="true" v-model="user.username" />

					<label class="text-white font-medium text-md">Password</label>
					<TextInput type="password" placeholder="Password" :required="true" class="mb-5 text-white" v-model="user.password" />

					<div class="inline-flex items-end justify-between w-full mt-1.5 mb-6">
						<Checkbox labelClass="text-white text-md">Remember me</Checkbox>
						<a class="text-md font-medium text-primary hover:underline" href="#">Forgot password? </a>
					</div>
										
					<Button type="submit" class="bg-primary w-full">Sign in</Button>

					<!-- Third party authenticator platforms -->
					<!-- 
					<div class="flex items-center mt-8">
						<div class="flex-auto mt-px border-t"></div>
						<div class="mx-2 text-secondary">Or continue with</div>
						<div class="flex-auto mt-px border-t"></div>
					</div>
					<div class="flex justify-center items-center mt-8 space-x-12">
						<i class="fab fa-facebook-square text-gray-400 text-2xl w-6"></i>
						<i class="fab fa-twitter text-gray-400 text-2xl w-6"></i>
						<i class="fab fa-linkedin text-gray-400 text-2xl w-6"></i>
					</div>
					-->
				</form>
			</div>
		</div>
		<div class="relative hidden md:flex flex-auto items-center justify-center w-1/2 h-full p-16 overflow-hidden bg-primary-700 dark:bg-gray-700 text-white ng-tns-c157-1">
			<svg viewBox="0 0 1531 891" preserveAspectRatio="xMidYMax slice"
				xmlns="http://www.w3.org/2000/svg" class="absolute top-0 left-0 w-full h-240 pointer-events-none text-primary-100 dark:text-gray-900 ng-tns-c157-1">
				<path d="M0 182c144.327 47.973 534.462 219.712 440.509 369.87C346.555 702.028 79.877 662.846 0 638V182z" class="fill-current opacity-10 ng-tns-c157-1"></path>
				<path d="M1405 848c-424.366 158.009-437-164-437-272s102-425 563-576v769c-21.333 29.333-63.333 55.667-126 79z" class="fill-current opacity-10 ng-tns-c157-1"></path>
				<path d="M1531 162c-122.914-17.284-377.96 33.191-543.433 206.414C822.095 541.636 797.342 648.75 835.842 731.622c38.5 82.871 198.243 134.841 400.555 92.053C1438.71 780.886 1492.752 775.894 1531 768V162z" class="fill-current opacity-10 ng-tns-c157-1"></path>
			</svg>
			<div class="w-full max-w-md">
				<div class="text-6xl font-bold leading-tight">
					<div class="">Well-crafted,</div>
					<div class="">Coded with attention,</div>
					<div class="">Build with passion.</div>
				</div>
				<div class="mt-3 opacity-70 text-lg"> 
					A little introduction showing how this software helps - make it around 2 sentences long if possible. 
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import User from '../models/user';

	export default {
		name: 'Login',
		data() {
			return {
				user: new User('', ''),
				testing: '', 
				test2: 'ok',
				loading: false,
				message: false
			};
		},
		computed: {
			loggedIn() {
				return this.$store.state.auth.status.loggedIn;
			}
		},
		created() {			
			if (this.loggedIn) {
				this.$router.push('/about');
			}
			document.getElementsByTagName('body')[0].classList.add('dark');
		},
		unmounted() { 
			document.getElementsByTagName('body')[0].classList.remove('dark');
		},
		methods: {
			handleLogin() {
				if (this.user.username && this.user.password) {
					this.$store.dispatch('auth/login', this.user).then(
						() => {
							this.$router.push('/about');
						},
						error => {
							console.log(error);
							this.message = ( error.response && error.response.data && error.response.data.error ) || 'An unknown error occured. Please try again.';
						}
					);
				}      
			}
		}
	}    
</script>

<style>
/*
@media only screen and (max-width: 600px) {
	#app { 
		background-color: rgb(30,41,59) !important;
	}
}	
*/
</style>